import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InstagramService {
  private access_token =
    'IGAAXbn3Vj1yxBZAE1ERjF3T29OTzZAYLXQxQzAzMzNlMmpvMEV4ZAldyeG1ROElaYUhmYW5ZAWU5PZAjZALZAjRFMDIzbllPcWpIMXMyc0xFa0xmdkg0VEFPSjNwV0xURV9FaHBlUHdjR0MyWlBMRzBLTWxUVUVB'; // your instagram access token. Consult instagram api documentation of how to retrieve it. Remember that it needs to be refreshed periodically

  constructor(private http: HttpClient) {}

  /**
   * Function to retrieve the latest x posts from your instagram timeline
   * @param limit how many posts do you want to get back
   * @returns an observable of the api call to the instagram api
   */
  getImages(limit: number) {
    return this.http.get(
      'https://graph.instagram.com/me/media?fields=id,caption,thumbnail_url,media_type,media_url,timestamp,children{media_type,media_url,thumbnail_url}&access_token=' +
        this.access_token +
        '&limit=' +
        limit.toString()
    );
  }

  /**
   * Hardcoded dummy function to get instagram data without a access token. Only use for testing. Needs to be updated with correct data regularly
   * @returns an observable of dummy api data
   */
  getImagesDummy() {
    return of({
      data: [
        {
          id: '17966786594193522',
          caption:
            'Short but successful trip to Turin. 🥇🤗\nNow we have one more week of training at home before we fly to Montreal for the second World Cup. 🙌🏼💧',
          media_type: 'CAROUSEL_ALBUM',
          media_url:
            'https://scontent.cdninstagram.com/v/t51.29350-15/342556999_1607336786451936_5045115588647997097_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=iCKjZglj6O8AX8etyNI&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfDexGBoBFk4lgE4Fpw7SYLtHyNPuQq7LygwyPAThjBDRg&oe=645035ED',
          timestamp: '2023-04-23T16:13:35+0000',
          children: {
            data: [
              {
                media_type: 'IMAGE',
                media_url:
                  'https://scontent.cdninstagram.com/v/t51.29350-15/342556999_1607336786451936_5045115588647997097_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=iCKjZglj6O8AX8etyNI&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfDexGBoBFk4lgE4Fpw7SYLtHyNPuQq7LygwyPAThjBDRg&oe=645035ED',
                id: '17994113101890795',
              },
              {
                media_type: 'IMAGE',
                media_url:
                  'https://scontent.cdninstagram.com/v/t51.29350-15/342386102_1168373460515635_3429727707968500323_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=7FSiy--oh6EAX9_ueJC&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfA70PkQf1xVT936n9HwTFOpsorcFTKjR89W7Qsm_RU2Tg&oe=644F9317',
                id: '18219809698170185',
              },
            ],
          },
        },
        {
          id: '17990484535919459',
          caption:
            'World Cup Xi’an 🇨🇳\nAn 8th place finish to kick off the international season. 🙏🏼 A few days at home before we travel to Turin this Friday for the next competition. 💧🤩',
          media_type: 'IMAGE',
          media_url:
            'https://scontent.cdninstagram.com/v/t51.29350-15/341739265_754992866004214_5998827943630901909_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=uw_eqGHlfQsAX-VlqTH&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfBEuZFIUD9S0Az2TnWTtlVNNVbgyzdpoOl6iMZyCZuyZg&oe=64502C84',
          timestamp: '2023-04-17T15:40:10+0000',
        },
        {
          id: '17850215375935637',
          caption:
            'It’s almost comp season. 🤩 Another week of training at home before we head to China for the first World Cup of the year. 💧💥\n📸 @magicpbk',
          media_type: 'IMAGE',
          media_url:
            'https://scontent.cdninstagram.com/v/t51.29350-15/338930233_934057344454796_3273285367067656217_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=Lor-X0TTH6kAX8FhG8d&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfBMRJ79AlQN8Ro20UFv2q_Exr6xQxz7lsor1iOa2m7uww&oe=644FA927',
          timestamp: '2023-04-02T16:00:39+0000',
        },
        {
          id: '18080206699329931',
          caption: 'early spring vibes 🌻🌞🫶🏼',
          media_type: 'IMAGE',
          media_url:
            'https://scontent.cdninstagram.com/v/t51.29350-15/332309920_665923261866684_4682349291012587862_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=VYBMc3aAhXUAX_ozfMU&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfCXAB6XPsIj1YFuvrnaBtrHb3IIM_KUryXfHTv66YJLTA&oe=644EB363',
          timestamp: '2023-02-22T15:02:07+0000',
        },
        {
          id: '17889133643775356',
          caption:
            '205B & 305B - really happy with these two dives from yesterday’s competition. 💧💃🏼😁',
          thumbnail_url:
            'https://scontent.cdninstagram.com/v/t51.29350-15/327710771_1395686261199436_2330994491207151294_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=fj-3o2srMDwAX_lJ-n6&_nc_oc=AQkBXPChz1dc-NTrdTmOrGWcR0BC9EuL6nYo2PuAf2TjfV62Ya53gEpYS-M7xFqSq_s&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfCjw9oEtMEiB3pwuM_9E0AW6gBbf5QhVTJrCksuTBOHsg&oe=644F2514',
          media_type: 'VIDEO',
          media_url:
            'https://scontent.cdninstagram.com/o1/v/t16/f1/m82/3E4CD010605EEE1B216A62D26C02ACB0_video_dashinit.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5jbGlwcyJ9&_nc_ht=scontent.cdninstagram.com&_nc_cat=103&vs=1347734799310496_1804919245&_nc_vs=HBksFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC8zRTRDRDAxMDYwNUVFRTFCMjE2QTYyRDI2QzAyQUNCMF92aWRlb19kYXNoaW5pdC5tcDQVAALIAQAVABgkR0NCeWV4TVg0NFdmYXg0REFPNTY0NjVNYUxORWJxX0VBQUFGFQICyAEAKAAYABsBiAd1c2Vfb2lsATEVAAAm8oKL2f%2FKxT8VAigCQzMsF0Ar8SbpeNT%2BGBJkYXNoX2Jhc2VsaW5lXzFfdjERAHUAAA%3D%3D&ccb=9-4&oh=00_AfBzr96s4oPGBit_B0_DEx6Rw-kCywOGEwfCg6oSilcIKw&oe=644C8554&_nc_sid=ea0b6e&_nc_rid=3f2ca5c183',
          timestamp: '2023-01-30T14:00:57+0000',
        },
        {
          id: '17968556483147832',
          caption:
            'New PB on 3m and 2x🥇 at the Swiss Nationals this weekend. 🇨🇭🤩 #moretocome #seasonopener',
          media_type: 'IMAGE',
          media_url:
            'https://scontent.cdninstagram.com/v/t51.29350-15/327917179_903159374170568_8491103165590188619_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=S6IH1UDF4CcAX9JlhWj&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfDN8AO66rIyyDn1ENP1-SoNIkIiFZVmeRhkIqNywsxfFw&oe=64508FB6',
          timestamp: '2023-01-29T17:36:42+0000',
        },
        {
          id: '17987748721782288',
          caption:
            'Training and building for the new season 2023. ✨🤩 Grazie Roma - è sempre un piacere. 🫶🏼',
          thumbnail_url:
            'https://scontent.cdninstagram.com/v/t51.29350-15/326001101_5843958512354431_2238024165169762633_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=S07ZznUGZbEAX9ApDEQ&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfDsrapWGaEGM2eubxCr6wRHuWlUiiX-DTogsQgMv2HMdQ&oe=644F0B52',
          media_type: 'VIDEO',
          media_url:
            'https://scontent.cdninstagram.com/o1/v/t16/f1/m82/AA4054596CF8C060C30598AE10AF42BC_video_dashinit.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5jbGlwcyJ9&_nc_ht=scontent.cdninstagram.com&_nc_cat=104&vs=885456482696991_2598845392&_nc_vs=HBksFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC9BQTQwNTQ1OTZDRjhDMDYwQzMwNTk4QUUxMEFGNDJCQ192aWRlb19kYXNoaW5pdC5tcDQVAALIAQAVABgkR0c0N1JoTW5YMkY2ZWJzQUFEU3YyMXJnWE9RTWJxX0VBQUFGFQICyAEAKAAYABsBiAd1c2Vfb2lsATEVAAAm%2BOTGh9L3vj8VAigCQzMsF0A1g1P3ztkXGBJkYXNoX2Jhc2VsaW5lXzFfdjERAHUAAA%3D%3D&ccb=9-4&oh=00_AfDsArKAnsqhcQraOlhryNMqlTaASdfzwoRFV1KNeubwwg&oe=644C1752&_nc_sid=ea0b6e&_nc_rid=a9dca55c20',
          timestamp: '2023-01-17T16:31:39+0000',
        },
        {
          id: '18230045365095054',
          caption:
            '2022 - the year in which I grew as a person, learned and experienced a lot and had many happy moments. Grateful for every single person who made this year unique. 🫂🤍\nbye bye 2022 and bring it on 2023! 🍾\n\n📸: @thilolarsson',
          media_type: 'IMAGE',
          media_url:
            'https://scontent.cdninstagram.com/v/t51.29350-15/322508815_187136157306136_7930135681727562991_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=4wHx3v2O-PQAX-vT2QY&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfBFZl01n-VOiPG65ZyjcnBb_Q3LNLJSzma1MPc-1mHYRA&oe=644ED85E',
          timestamp: '2022-12-29T15:56:33+0000',
        },
        {
          id: '17978225749647314',
          caption:
            'Two days ago I had the opportunity to talk about the importance of the support of the Swiss Armed Forces for me as an athlete. Thank you ARMSI (Associazione per la Rivista Militare Svizzera di lingua italiana) for having me and thank you @armee.ch_sport for the support. 🫶🏼🇨🇭',
          media_type: 'IMAGE',
          media_url:
            'https://scontent.cdninstagram.com/v/t51.29350-15/312044433_493557795991799_1983425584382620223_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=dHVb3z9GJO4AX-8qZzE&_nc_oc=AQnWR2qR0nzPE7nSAxfZMPr4-5DbmxToUQ-HAqeLwUIVYK5nKYj1Lphe3kFAOrxShQo&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfDCRv_erOkH5siLmcG2lyNOGRdUxaRTw-VmXZsdflJVmg&oe=64507A07',
          timestamp: '2022-10-20T15:46:42+0000',
        },
      ],
      paging: {
        cursors: {
          before:
            'QVFIUmxkVHZANM29uZAEpjcmoxb3VXRDVCeTFTc1V4SHpha1VlSVN0V2xxVlRvWE5SejVUdzRnV2Y4anZACYktRYU9ZAX3VOYjJPU1lfV0hTZAUhURkozQTEteVhR',
          after:
            'QVFIUmpmV3JuZAC0zUGdwOXBIRmZALZAHRBT2JkWElrZAk5KazNkRnNhUjlDZATNaM1pSb29LeUpveExTdWFvbnR6WkJ0RkZAwLU5iQVZAXWnlKTlFhR1dXcFVGUjVR',
        },
        next: 'https://graph.instagram.com/v15.0/17841400136236798/media?access_token=IGQVJVemZAhTFozdlppaGhEQlVQWkVrLWlMWGFFdHhwbndNVndHTkV3WlNhcXZAuSDRpQWZALYWdNbEdNajkySllORFBXRk9yRzNLMTljZAG5uYjdGZAnljdnV2UDNWVVdxMGQyb3I4bHlB&pretty=1&fields=id%2Ccaption%2Cthumbnail_url%2Cmedia_type%2Cmedia_url%2Ctimestamp%2Cchildren%7Bmedia_type%2Cmedia_url%2Cthumbnail_url%7D&limit=9&after=QVFIUmpmV3JuZAC0zUGdwOXBIRmZALZAHRBT2JkWElrZAk5KazNkRnNhUjlDZATNaM1pSb29LeUpveExTdWFvbnR6WkJ0RkZAwLU5iQVZAXWnlKTlFhR1dXcFVGUjVR',
      },
    });
  }
}
