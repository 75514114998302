<h1>Über mich</h1>
<div class="top">
  <div class="text">
    <p>
      Ich bin am 02. Juni 2000 in Wettingen (AG) geboren und in Fislisbach (AG)
      aufgewachsen. Mein sportlicher Werdegang begann im Jahr 2004, als ich mein
      erstes Schnuppertraining im Kunstturnen absolvierte. Schnell entdeckte ich
      die Faszination dieser Sportart und blieb dem Kunstturnen während 8 Jahren
      treu. Zwei Kniescheibenbrüche in den Jahren 2011/2012 zwangen mich dann
      aber, meine erste Leidenschaft aufzugeben.<br />
      Mein grosser Traum war es jedoch, einmal an Olympischen Spielen
      teilzunehmen. Deshalb war für mich klar, dass meine sportliche Laufbahn
      trotz dieses Rückschlags noch nicht zu Ende sein kann.<br />
      Nach diversen Probetrainings in verschiedenen Sportarten entdeckte ich
      schliesslich das Wasserspringen für mich. Seit 2012 betreibe ich nun diese
      Sportart und darf die Schweiz stolz auf internationaler Ebene
      vertreten.<br />
      Neben dem Wasserspringen studiere ich Kommunikationswissenschaften und
      Medienforschung an der Universität Zürich.
    </p>
  </div>
</div>
<div class="bottom">
  <div class="avatar">
    <img
      class="avatar-image"
      src="assets/img/avatar_patrick_b_kreamer.JPG"
      alt="Michelle im Profil"
    />
  </div>
  <div class="results-container">
    <h2>Grösste Erfolge</h2>
    <ul class="results">
      <li class="one">Europas Wasserspringerin des Jahres 2023</li>
      <li class="one">1m-Brett Europameisterschaften 2023</li>
      <li class="three">3m-Brett Europameisterschaften 2023</li>

      <li class="six">3m-Brett Weltmeisterschaften 2022</li>
      <li class="two">3m-Brett Europameisterschaften 2022</li>
      <li class="two">1m-Brett Europameisterschaften 2021</li>
      <li class="eleven">Finalteilnahme Olympische Spiele Tokio 2020</li>
      <li class="three">3m-Brett Mixed Synchron Europameisterschaften 2019</li>
      <li class="two">3m-Brett Europameisterschaften 2017</li>
      <li class="one">27 Schweizermeistertitel</li>
    </ul>
  </div>
</div>
