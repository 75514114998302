<div class="container">
  <h1>Wettkämpfe</h1>
  <div class="wrapper">
    <h2>2025</h2>
    <a
      href="https://www.worldaquatics.com/competitions/4864/67th-international-divers-day"
      target="_blank"
      >13. – 16. März 2025: 67th International Divers' Day &#64; Rostock
      (GER)</a
    >
    <a
      href="https://www.worldaquatics.com/competitions/4847/world-aquatics-diving-world-cup-2025"
      target="_blank"
      >03. – 06. April 2025: World Aquatics Diving World Cup &#64; Guadalajara
      (MEX)</a
    >
    <a
      href="https://www.worldaquatics.com/competitions/4783/world-aquatics-diving-world-cup-2025"
      target="_blank"
      >10. – 13. April 2025: World Aquatics Diving World Cup &#64; Windsor
      (CAN)</a
    >
    <a
      href="https://www.worldaquatics.com/competitions/4784/world-aquatics-diving-world-cup-2025-super-final"
      target="_blank"
      >02. – 04. Mai 2025: World Aquatics Diving World Cup &#64; Peking (CHN)</a
    >
    <a href="https://www.len.eu/len-calendar/" target="_blank"
      >25. – 31. Mai 2025: European Aquatics Diving Championships &#64; TBC</a
    >
    <a
      href="https://www.swiss-aquatics.ch/leistungssport/diving/agenda/"
      target="_blank"
      >13. – 15. Juni 2025: Schweizer Meisterschaften &#64; Bern Neufeld</a
    >
    <a href="https://www.divingmeeting.it/" target="_blank"
      >04. – 06. Juli 2025: Bolzano Diving Meeting &#64; Bozen (ITA)</a
    >
    <a
      href="https://www.worldaquatics.com/competitions/4725/world-aquatics-championships-singapore-2025"
      target="_blank"
      >26. Juli – 03. August 2025: World Aquatics Championships &#64; Singapur
      (SGP)</a
    >
  </div>
</div>
